import './LocationView.css'
import CameraIcon from "./CameraIcon"
import RouterIcon from './RouterIcon'
import TargetIcon from './TargetIcon'

function LocationView() {
    const imageurl = process.env.PUBLIC_URL + '/assets/trzuskawica.jpg'
    return (
        <div className='BackgroundImage' style={{ backgroundImage: `url(${imageurl})` }}>
            <div style={{ position: 'relative' }}>
                <CameraIcon xPosition='410px' yPosition='270px' />
                <RouterIcon xPosition='450px' yPosition='280px' />
                <TargetIcon xPosition='350px' yPosition='270px' />
            </div>
        </div >
    )
}

export default LocationView