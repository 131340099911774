
import { useEffect, useState } from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, Tabs } from 'antd';
import { DESDataModel } from '../services/DESDataModel';
import axios from 'axios';
import { usePapaParse } from 'react-papaparse';
import useObjectStatusStore from '../../../Store/ObjectStatus';
import { LocationsEnum } from '../../../Store/ObjectStatusEnum';

function Graphs() {

    const { readString } = usePapaParse();
    const location = useObjectStatusStore((state) => state.location)

    let url = ''
    let range = [-1, 1]
    let dataFormat = 'M/DD/YYYY HH:mm:ss'
    switch (location) {
        case LocationsEnum.Trzuskawica:
            url = 'trzuskawica'
            range = [-1, 1]
            dataFormat = 'M/DD/YYYY HH:mm:ss'
            break;
        default:
            break;
    }

    const [kameraRaw, setKameraRaw] = useState<DESDataModel[]>([])
    const [kamera1, setKamera1] = useState<DESDataModel[]>([])

    const { RangePicker } = DatePicker;
    const [dateFromPickerSetValue, setDateFromPickerSetValue] = useState<number>(-1);
    const [dateToPickerSetValue, setDateToPickerSetValue] = useState<number>(-1);

    const [time, setTime] = useState(1);
    useEffect(() => {
        getData1()
        setDateFromPickerSetValue(dayjs().add(-7, 'd').unix())
        setDateToPickerSetValue(dayjs().unix())
    }, [time]);

    useEffect(() => {
        setKamera1(kameraRaw.filter((data: DESDataModel) => (data.DateTimeOriginalTimeStamp > dateFromPickerSetValue && data.DateTimeOriginalTimeStamp < dateToPickerSetValue)))
    }, [kameraRaw, dateFromPickerSetValue, dateToPickerSetValue]);

    async function getData1() {
        const response = await axios.get(`../assets/${url}1.csv`, { responseType: 'blob', }).then((response) => {
            const CSV = response.data as string
            readString(CSV, {
                header: true,
                worker: true,
                complete: (results) => {
                    const data = (results.data as DESDataModel[]).map(x => {
                        let dmYInt = (Math.round((Number(x.dyMm) + Number.EPSILON) * 10000) / 10000) * -1;
                        x.dyMm = dmYInt;
                        x.DateTimeOriginalTimeStamp = dayjs(x.DateTimeOriginal, dataFormat).unix()
                        return x;
                    })
                    setKameraRaw(data)
                },
            });
        });
    }


    const rangePresets: {
        label: string;
        value: [Dayjs, Dayjs];
    }[] = [
            { label: 'Ostatnie 7 dni', value: [dayjs().add(-7, 'd'), dayjs()] },
            { label: 'Ostatnie 14 dni', value: [dayjs().add(-14, 'd'), dayjs()] },
            { label: 'Ostatnie 30 dni', value: [dayjs().add(-30, 'd'), dayjs()] },
        ];

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates) {
            const from = dates[0]?.startOf('day').unix();
            const to = dates[1]?.endOf('day').unix();
            if (from && to) {
                setDateFromPickerSetValue(from);
                setDateToPickerSetValue(to);
            }
        } else {
            console.log('Clear');
        }
    };

    console.log(kamera1)

    return (
        <><div>
            <RangePicker onChange={onRangeChange} presets={rangePresets} defaultPickerValue={[dayjs().add(-7, 'd'), dayjs()]} defaultValue={[dayjs().add(-7, 'd'), dayjs()]} />
        </div><div>
                <h1>Kamera 1 przemieszczenie pionowe</h1>
                <ResponsiveContainer width="95%" height={350}>
                    <LineChart
                        data={kamera1}
                        margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                    >
                        <XAxis dataKey="DateTimeOriginal" />
                        <YAxis type="number" domain={range} />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line type="monotone" dataKey="dyMm" stroke="#000dff" yAxisId={0} />
                    </LineChart>
                </ResponsiveContainer>
            </div></>
    )
}

export default Graphs