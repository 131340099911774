import './ProhibitionLimitSign.css'

function ProhibitionLimitSign(props: any) {
    const textDisplay = props.textDisplay;
    const textSubDisplay = props.textSubDisplay;

    return (
        <div id="road-sign">
            <div id="sign-center">
                <div id="limit">
                    {textDisplay}<sub>{textSubDisplay}</sub>
                </div>

            </div>
        </div>
    )
}

export default ProhibitionLimitSign