
export enum ObjectStatusEnum {
    none = 'Brak',
    safe = 'Bezpieczny',
    warning = 'Ostrzegawczy',
    alert = 'Alarmowy'
}

export enum LocationsEnum {
    none = 'None',
    KCSiR = 'KCSiR',
    Ratusz = 'Ratusz',
    Trzuskawica = 'Trzuskawica',
}