
import { create } from 'zustand'
import { LocationsEnum, ObjectStatusEnum } from './ObjectStatusEnum'

type State = {
    location: LocationsEnum,
    logo: string,
    objectStatus: ObjectStatusEnum
    setStatus: (status: ObjectStatusEnum) => void
    setLogo: (status: string) => void
    setLocation: (location: LocationsEnum) => void
}

const useObjectStatusStore = create<State>((set) => ({
    location: LocationsEnum.none,
    logo: '',
    objectStatus: ObjectStatusEnum.none,
    setStatus: (status: ObjectStatusEnum) => set((state) => ({ ...state, objectStatus: status })),
    setLogo: (logo: string) => set((state) => ({ ...state, logo })),
    setLocation: (location: LocationsEnum) => set((state) => ({ ...state, location }))
}))

export default useObjectStatusStore

