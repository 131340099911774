import LocationView from "../../LocationView/components/LocationView";
import ProhibitionLimitSign from "./ProhibitionLimitSign";
import './StatusDashboard.css'

function StatusDashboard() {

    return (
        <div className="StatusDashboard">
            <div className="side">
                <LocationView />
            </div>
            <div className="side">
                <div className="RestrictionsText">
                    <p>Obowiązujące ograniczenia</p>
                </div>
                <div className="RestrictionsSigns">
                    <ProhibitionLimitSign textDisplay={'10'} />
                    <ProhibitionLimitSign textDisplay={'88'} textSubDisplay={'t'} />
                </div>
            </div>

        </div >
    )
}

export default StatusDashboard;