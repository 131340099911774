import { BiCctv } from "react-icons/bi"


function CameraIcon(props: any) {

    const xPosition = props.xPosition;
    const yPosition = props.yPosition;

    return (
        <div style={{ position: 'absolute', left: xPosition, top: yPosition }}>
            <div id='icons' className='icon shadow-4'>
                <BiCctv style={{ color: 'white' }} size="20px" />
            </div>
        </div>
    )
}

export default CameraIcon