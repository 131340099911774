import { Tabs } from 'antd';
import './App.css';
import './shadow.css';
import TopBar from './features/topBar/TopBar';
import TabPane from 'antd/es/tabs/TabPane';
import Graphs from './features/Charts/components/Graphs';
import StatusDashboard from './features/RestrictionsSigns/components/StatusDashboard';
import Footer from './features/Footer/components/Footer';
import useObjectStatusStore from './Store/ObjectStatus';
import { LocationsEnum, ObjectStatusEnum } from './Store/ObjectStatusEnum';

function App() {

  const setLogo = useObjectStatusStore((state) => state.setLogo)
  const setLocation = useObjectStatusStore((state) => state.setLocation)
  const setStatus = useObjectStatusStore((state) => state.setStatus)

  setStatus(ObjectStatusEnum.safe)
  setLogo('kcsir_logo.jpeg')
  setLocation(LocationsEnum.Trzuskawica)

  return (
    <div className="App">
      <TopBar />
      <div className="App-container">
        <StatusDashboard />
        <Graphs />
      </div>
      <Footer />
    </div >
  )
}

export default App;